import axios from "axios";
import { baseURL } from "./baseURL.service";

export const handleCheckout = async (cartItems) => {

    const updatedCartItem = { ...cartItems, quantity: 1 };
    const product = [updatedCartItem]
    //console.log(product, 'product twtwtwwt')
    try {
        const { status, data } = await axios.post(`${baseURL}/checkout`, { products: product });
        console.log(data, 'data')
        if(status===200){
            window.location.href = data.url;
        }
    } catch (error) {
        console.error('Error during checkout:', error);
        throw error
    }
};


export const generateWhatsAppLink = (product) => {
    console.log(product, 'pro')
    const message = `Hello, I am interested in the product from your online shop: \n
    Name: \t ${product.name} \n
    Price: \t ${product.price}\n
    Image: \t ${product.image}\n
    Could you provide more details?`;
    const whatsAppLink = window.location.href = `https://wa.me/+447300999608?text=${encodeURIComponent(message)}`;
    return whatsAppLink
};