
import { SectionsCard } from '../cards';
import { WorshipSession } from './others';




const Sections = () => {
  return (
    <>
        <SectionsCard
          image={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_204518_tpjjxn.jpg'}
          subTitle='Empress'
          title='Empress Wrap Blouse'
          content='
          The Empress Wrap Blouse is designed for the woman who loves to mix comfort with style. Featuring a flattering wrap-around design, this blouse can be adjusted to your desired fit. Its lightweight, silky material and vibrant colors make it an ideal choice for both office wear and casual outings.
          '
        />
        <SectionsCard
          image={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641601/20240730_195030_bq1axb.jpg'}
          subTitle='Serenity'
          title='Serenity Maxi Dress'
          content='Embrace effortless elegance with our Serenity Maxi Dress, a flowing piece that drapes beautifully on all body types. Made from soft, breathable fabric, this dress is perfect for warm days or evening events. The subtle floral pattern adds a touch of femininity, while the adjustable straps ensure a perfect fit every time.'
        />
        <WorshipSession
          title='Why Choose Zobs Wearables?'
          content='At Zobs, we are committed to providing not only stylish clothing but also a shopping experience that values quality, inclusivity, and customer satisfaction. Each piece is thoughtfully designed to meet the diverse needs of women everywhere. We take pride in our attention to detail, from the choice of materials to the final stitch, ensuring that every item you purchase from Zobs Wearables is a true work of art. '
        />
        <SectionsCard
          image={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641593/20240730_192222_n1yhze.jpg'}
          subTitle='Radiance '
          title='Radiance Evening Gown'
          content='Turn heads at any event with the Radiance Evening Gown. This full-length gown features a figure-hugging bodice and a flowing skirt, creating a stunning contrast. The intricate lace details and subtle shimmer add an air of sophistication, making it the perfect choice for formal occasions.'
        />
        <SectionsCard
          image={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641611/20240730_205651_shdw0u.jpg'}
          subTitle='Dashboards'
          title='Empowerment High-Waist Trousers'
          content='These high-waist trousers are tailored to perfection, offering a sleek silhouette that complements any figure.'
          centered={true}
        />
    </>
  )
}

export default Sections