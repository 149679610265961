
import { useNavigate } from 'react-router-dom';

import { FaHome } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { LiaTimesSolid } from "react-icons/lia";


const MobileNavigation = ({ showNavItems, handleToggleShowNavitems }) => {
   
    const navigateTo = useNavigate();
    const navItems = [
        {
            name:'Home',
            url:'/',
            icon:<FaHome />
        },
        {
            name:'Shop',
            url:'/shop',
            icon:<TbListDetails />
        },
        // {
        //     name:'Givings',
        //     url:'/givings',
        //     icon:<FaGifts />
        // },
        // {
        //     name:'Contact Us',
        //     url:'/contact-us',
        //     icon:<MdContactSupport />
        // },
    ]
  return (
    <div className='h-screen  relative'>
        {
            showNavItems && (
                <div className='h-full bg-customPrimary/90 absolute top-0 w-full flex flex-col justify-center items-center gap-5'>
                    <span onClick={handleToggleShowNavitems}><LiaTimesSolid className='text-white rounded-full border p-1' /></span>
                    <ul className='flex flex-col items-center gap-3 text-white font-primary font-bold'>
                        {
                            navItems && navItems.map((item, index)=>(
                                <li 
                                onClick={()=>{
                                    navigateTo(item.url)
                                    handleToggleShowNavitems()
                                }}
                                key={index}>{item.name}</li>
                            ))
                        }
                    </ul>
                </div>
            )
        }
    </div>
  )
}

export default MobileNavigation