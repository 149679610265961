import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../button';
import heroImage from '../../assets/png/hero-image.png'
import { DesktopNavigation, MobileNavigationHeader, MobileNavigation } from '../header';
import { Link } from 'react-router-dom';



const Hero = () => {
    const [showLiveStreamModal, setShowLiveStreamModal] = useState(false);
    const [showNavItems, setShowNavItems] = useState(false);

    const handleToggleShowNavitems = ()=>{
        setShowNavItems(!showNavItems)
    }

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    const buttonVariants = {
        hidden: { opacity: 0, x: -70 },
        visible: { opacity: 1, x: 0 },
    };


  return (
    <div className='h-screen font-primary'>
        <div className='h-full w-full relative'>
            <img src={heroImage} className='h-full w-full object-cover' />
            <div className='h-full flex absolute w-full top-0'>
                <div className='h-full w-1/2 bg-red-500 opacity-30'>
                    
                </div>
                <div className='h-full w-1/2 bg-customPrimary opacity-50'>

                </div>
            </div>
            <div className='h-full w-full bg-customPrimary opacity-50 absolute top-0 left-0'>
                
            </div>
            <div className='h-full flex flex-col items-cente justify-center absolute w-full top-0  font-primary'>
                <MobileNavigationHeader 
                    handleToggleShowNavitems={handleToggleShowNavitems}
                />
                <div className='md:w-9/12 m-auto text-white text-center'>
                    <h3 className='md:ml-3 font-light font-subPrimary text-sm'>A Distinct Fashion House</h3>
                    <motion.h1 
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className='font-xl font-xbold text-lg md:text-[130px] 
                    leading-[3rem] md:leading-[7rem]'>
                        Zobs <br />Wearables
                    </motion.h1>
                    <div className='flex flex-col md:flex-row justify-center items-center gap-2 md:gap-5 mt-4 md:ml-2' >
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariants}
                            transition={{ duration: 1.2, delay: 0.2 }}
                            className='md:w-6/12'
                        >
                            <Link to={'/shop'}>
                                <Button
                                    // icon={<FaLongArrowAltRight/>}
                                    disabled={false}
                                    isBorder={true}
                                >
                                    Visit our shop
                                </Button>
                            </Link>
                        </motion.div>
                        {/* <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariantsRight}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        >
                            <Button
                                disabled={false}
                                isBorder={true}
                            >
                                Create Agent Account
                            </Button>
                        </motion.div> */}
                    </div>
                </div>
            </div>
            <div className='absolute top-0 w-full'>
                <DesktopNavigation />
            </div>
            {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default Hero