

import React, { useEffect } from 'react';
import axios from 'axios';
import { useCartContext } from '../../contexts/cartContext';
import { ICartProps } from '../../types/ICartProps';

const Cart: React.FC<ICartProps> = ({ cartItems, removeFromCart }) => {
    const { showCart, setShowCart, handleShowCart, cartRef } = useCartContext();



    const handleCheckout = async () => {
        try {
            const { status, data } = await axios.post('http://localhost:5000/api/checkout', { products: cartItems });
            console.log(data, 'data')
            if(status===200){
                window.location.href = data.url;
            }
        } catch (error) {
            console.error('Error during checkout:', error);
            throw error
            // Handle error appropriately
        }
    };

    useEffect(()=>{
        if(cartItems.length === 0){
            setShowCart(false)
        }else{
            setShowCart(true);
        }
    }, [cartItems])

    return (
        <>
            {
                showCart && (
                    <div className='h-full w-full absolute top-0 left-0 bg-customPrimary/60 z-[999]' ref={cartRef}>
                        <div className='flex flex-right bg-white'>
                            <div className='h-full w-3/12 absolute top-0 right-0 bg-white'>
                                {cartItems?.length === 0 ? (
                                    <p>Cart is empty</p>
                                ) : (
                                    cartItems.map((item, index) => (
                                        <div key={index}>
                                            <h3>{item.name}</h3>
                                            <p>Price: £{item.price}</p>
                                            <p>Quantity: {item.quantity}</p>
                                            <button onClick={() => removeFromCart(item)}>Remove</button>
                                        </div>
                                    ))
                                )}
                                {cartItems?.length > 0 && (
                                    <button onClick={handleCheckout}>Checkout</button>
                                )}

                                <button className='text-white bg-black py-2 px-3' onClick={handleCheckout}>checkout to pay</button>
                            </div>
                        </div>
                        <h2>Cart</h2>
                    </div>
                )
            }
        </>
    );
};

export default Cart;
