import React from 'react';

interface ButtonProps {
  children:React.ReactNode,
  isBorder?: boolean | null,
  // type:string,
  disabled:boolean
}

const Button:React.FC<ButtonProps> = ({ children, isBorder, ...props }) => {
  return (
    <button 
    className={`w-full ${isBorder ? ' bg-black hover:bg-slate-300' 
    : 'bg-customPrimary hover:bg-black/50'} py-5 rounded-md text-white 
    font-bold flex items-center justify-center px-7 ease-in duration-500 capitalize mt-3`}
        {...props}>
        { children }
    </button>
  )
}

export default Button