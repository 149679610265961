import React from 'react';

interface ButtonProps {
  children:React.ReactNode,
  isBorder?: boolean | null,
  onClick: () => void;
}

const CustomButton:React.FC<ButtonProps> = ({ children, isBorder, ...props }) => {
  return (
    <button 
    className={`w-full ${isBorder ? 'border border-white bg-black hover:bg-slate-300' 
      : 'bg-customPrimary hover:bg-black/50'} py-4 rounded-md text-white 
      font-bold flex items-center justify-center px-7 ease-in duration-500 capitalize mt-3`}
          {...props}>
          { children }
    </button>
  )
}

export default CustomButton