import React from 'react';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Button } from '../button';
import '../../assets/css/style.css'


const SectionsCard = ({ image, title, subTitle, content, centered }) => {

    const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      

  return (
    <div className='h-screen relative'>
        <div className="image-with-text bg-fixed" style={{ backgroundImage: `url(${image})` }}>
        </div>
        <div className='w-full h-full flex flex-col justify-center items-center 
        absolute left-0 top-0 bg-gradient-to-b from-customPrimary/25 to-customPrimary/100 font-primary'>
            <div className={`${centered ? 'w-full' : 'w-9/12 m-auto'} `}>
                <div className={centered ? 'text-center text-whiteSmoke' : 'text-whiteSmoke'}>
                    <h3 className='text-lg font-subPrimary'>{subTitle}</h3>
                    <motion.h4 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className={`md:w-${centered ? 'full text-center' : '4/12'} 
                    text-lg md:text-xl font-xbold leading-9 md:leading-[4.9rem] my-5`}>
                        {title}
                    </motion.h4>
                    <motion.p 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.4 }}
                    className={`${!centered && 'md:w-6/12 '} text-sm`}>{content}</motion.p>
                </div>
                {
                    !centered && (
                        <motion.div 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.6 }}
                        className='md:w-3/12'>
                            <Link to={'/shop'}>
                                <Button
                                    icon={<FaLongArrowAltRight />}
                                >
                                    Visit shop
                                </Button>
                            </Link>
                        </motion.div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default SectionsCard