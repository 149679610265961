


interface Iproducts {
    id: string,
    name: string,
    price: number,
    currency: string,
    productImageArray: string[],
    image: string
    description: string
}

const productOneArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641610/20240730_205910_zeyyhn.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641611/20240730_205651_shdw0u.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_205903_tzjfxs.jpg'
]
const productTwoArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641611/20240605_090632_fpmyve.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641610/20240605_090616_zo8aac.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641611/20240605_090632_fpmyve.jpg'
]
const productThreeArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641589/20240730_204650_c9xgoe.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_204642_jid1wg.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641589/20240730_204650_c9xgoe.jpg'
]
const productFourArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_203600_qotiia.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641601/20240730_203426_u8peq4.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641601/20240730_203426_u8peq4.jpg'
]
const productFiveArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641598/20240730_195754_ulnguv.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641597/20240730_195553_pth3hs.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641600/20240730_195927_trnfov.jpg'
]
const productSixArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641596/20240730_192401_wsnafs.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641595/20240730_192316_tm8hlq.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641599/20240730_192255_ro5bue.jpg'
]
const productSevenArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641596/20240730_191153_x9yqbe.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641591/20240730_190356_hdamqj.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641591/20240730_190356_hdamqj.jpg'
]
const productEightArray = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641592/20240730_184307_uhebsa.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641592/20240730_184307_uhebsa.jpg',
]
const product9Array = [
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641594/20240730_184929_exhggh.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641590/20240730_184901_wsvhpq.jpg',
    'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641599/20240730_185412_xcvapd.jpg'
]

export const products: Iproducts[] = [
    {   id: 'twtwt1ywywywwy', 
        name: 'Two piece floral Kimono Top and Pant.', 
        price: 35, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641609/20240730_205844_xl5r8k.jpg',
        productImageArray: productOneArray,
        description: 'Fabric type is Vintage, soft, and lovely on the skin on the skin.'
     },
    {   id: 'rwrwrw2jwjjsjsj', 
        name: 'Ankara Kimono Jacket.', 
        price: 25, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641611/20240605_090632_fpmyve.jpg',
        productImageArray: productTwoArray,
        description: 'Ankara Kimono Jacket.'
     },
    {   id: 'jdjdsidddkdkdkjdjd', 
        name: 'Scrub Neckline two pieces set (Blouse and Pant).', 
        price: 35, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_204518_tpjjxn.jpg',
        productImageArray: productThreeArray,
        description: 'Scrub Neckline two pieces set (Blouse and Pant). Set has ropes by the sides for rouched effects, pant is loose fit'
     },
    {   id: 'jdjksksknsssdjdjd', 
        name: 'Color Splash two peice set -Short.', 
        price: 27, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641605/20240730_203412_auzqoe.jpg',
        productImageArray: productFourArray,
        description: 'Color Splash two peice set -Short. Fabric is beautiful and lush'
     },
    {   id: 'jdjdhjsdsjsjjdjd', 
        name: 'Red Animal Skin Kimono two piece set', 
        price: 27, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641600/20240730_195927_trnfov.jpg',
        productImageArray: productFiveArray,
        description: 'Red Animal Skin Kimono two piece set'
     },
    {   id: 'jdjdjdjd', 
        name: 'White and Black Floral shirt and shirt', 
        price: 27, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641599/20240730_192255_ro5bue.jpg',
        productImageArray: productSixArray,
        description: 'White and Black Floral shirt and shirt'
     },
    {   id: 'jdjdhjjlllsjdjd', 
        name: 'White and Black Floral shirt and pant', 
        price: 35, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641596/20240730_191153_x9yqbe.jpg',
        productImageArray: productSevenArray,
        description: 'White and Black Floral shirt and pant'
     },
    {   id: 'jdjdjdjd', 
        name: 'Purple Theme two piece long', 
        price: 35, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641592/20240730_184307_uhebsa.jpg',
        productImageArray: productEightArray,
        description: 'Purple Theme two piece long'
     },
    {   id: 'jdjdjdjd', 
        name: 'Purple Theme floral shirt and short.', 
        price: 27, 
        currency: 'gbp', 
        image: 'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641599/20240730_185412_xcvapd.jpg',
        productImageArray: product9Array,
        description: 'Purple Theme floral shirt and short.'
     },
];
