import React, { useState} from 'react';
// import { TopHeader } from '../../components/header';
import { CarouselHero, Hero } from '../../components/hero';
import { Sections } from '../../components/sections';
import { Footer } from '../../components/footer';
import { DesktopNavigation, MobileNavigationHeader, MobileNavigation } from '../../components/header';

const Landing = () => {
  const [showNavItems, setShowNavItems] = useState(false);

  const handleToggleShowNavitems = ()=>{
    setShowNavItems(!showNavItems)
}
  return (
    <div>
        {/* <CarouselHero /> */}
        <Hero />
        <Sections />
        <Footer />
            <div className='absolute top-0 w-full'>
                <DesktopNavigation />
            </div>
          
            {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
    </div>
  )
}

export default Landing