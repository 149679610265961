import React, { useState, createContext, useContext, useEffect, useRef } from 'react'

const CartContext = createContext()
export const CartContextProvider = ({ children }) => {
    const [showCart, setShowCart] = useState(false);
    const cartRef = useRef(null);

      const handleShowCart = ()=>{
        setShowCart(!showCart);
    }

    // Function to handle clicks outside the cart modal
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
          setShowCart(false); // Close the modal if clicked outside
      }
  };

  useEffect(() => {
      if (showCart) {
          document.addEventListener('mousedown', handleClickOutside);
      } else {
          document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [showCart]);

  return (
    <CartContext.Provider value={{
        setShowCart,
        showCart,
        handleShowCart,
        cartRef
    }}>
        { children }
    </CartContext.Provider>
  )
}

export const useCartContext =()=> useContext(CartContext)