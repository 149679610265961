import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import {  FaYoutube  } from "react-icons/fa";
import { ProductDetailCard } from '../../components/cards';
import { CustomButton } from '../../components/button';
import { handleCheckout, generateWhatsAppLink } from '../../services/checkout.service';
import { Footer } from '../../components/footer';
import { DesktopNavigation, MobileNavigation, MobileNavigationHeader } from '../../components/header';

const ProductDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const product = location.state;

    const [showNavItems, setShowNavItems] = useState(false);

    const handleToggleShowNavitems = ()=>{
      setShowNavItems(!showNavItems)
  }

    console.log(product, 'prod==========')


    const SocialAccounts = [
      {
          name:'facebook',
          url:'https://www.facebook.com/Zobswearables/',
          icon:<FaFacebookF className='text-dark-tremor-brand-faint'/>
      },
      {
          name:'youtube',
          url:'https://www.youtube.com/@Sewwithzobs',
          icon:<FaYoutube className='' />
      },
      // {
      //     name:'Twitter',
      //     url:'',
      //     icon:<FaTwitter  className=''/>
      // },
      {
          name:'instagram',
          url:'https://www.instagram.com/zobswearables/',
          icon:<FaInstagram className='' />
      },
      
  ]

  

  return (
    <>
    <DesktopNavigation />
    <MobileNavigationHeader 
                    handleToggleShowNavitems={handleToggleShowNavitems}
                />
      <div className='md:h-screen w-9/12 flex flex-col md:flex-row gap-5 justify-center items-center m-auto font-primary my-10 md:my-0'>
          <div className='w-full md:w-1/2'>
            <ProductDetailCard productImages={product.productImageArray} />
          </div>
          <div className='md:w-1/2'>
            <h3 className='font-xl font-bold text-lg md:text-[40px] leading-[3rem] md:leading-[2.5rem]'>
              {product.name}
            </h3>
            <h3 className='font-xl font-xbold text-lg md:text-[40px] leading-[1rem] text-gray-600 my-10'>
            £{product.price}
            </h3>
            <div className='my-10'>
              <p>{product.description}</p>
            </div>
            <CustomButton
              isBorder={true}
              onClick={()=>handleCheckout(product)}
            >Buy Now</CustomButton>
            <button 
              onClick={()=>generateWhatsAppLink(product)}
            className='w-full py-4 flex justify-center items-center gap-3 bg-teal-500 text-white font-bold mt-1'>
              <FaWhatsapp className='text-white font-bold h-6 w-6'/>Buy Via WhatsApp</button>

            <div className='my-10'>
              {/* <b>Ask a Question</b> <br /> <br /> */}
              <b>Availability:</b> In Stock <br />
              {/* <b>Categories:</b> Bags Coats & Jackets Jewelry Tops <br /> */}
              <b>Tags:</b> Acessories, Fashion, New Fashion <br />
            </div>
            <ul className='flex items-center gap-5'>
          {
              SocialAccounts.map((item, index)=>(
              <a 
                href={item.url}
                key={index}
                className='flex items-center gap-2 text-whit cursor-pointer hover:text-yellow ease-in duration-500'
              >
                <span>{item.icon}</span></a>
              ))
            }
      </ul>
          </div>
      </div>
      {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
      <Footer />
    </>
  )
}

export default ProductDetails