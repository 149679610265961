
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";
import logo from '../../assets/jpg/zobs-wearables-logo.jpg';

const MobileNavigationHeader = ({ handleToggleShowNavitems }) => {
  const navigateTo = useNavigate();
  return (
    <div className='flex items-center justify-between px-5 py-2 mt-0 md:hidden bg-light'>
        <img 
        onClick={()=>navigateTo('/')}
        src={logo} alt="zobs wearables" 
        className='w-12 h-12 border-2 border-customPrimary rounded-full cursor-pointer  p-1' />
        <span className='' onClick={handleToggleShowNavitems}><CiMenuBurger className='w-6 h-6 text-yellow font-xbold' /></span>
    </div>
  )
}

export default MobileNavigationHeader