import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

type TProductProps = {
    productImages: string[]
}

const ProductDetailCard = ({ productImages }:TProductProps) => {
  const [selectedImage, setSelectedImage] = useState<string>(productImages[0]);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const handlePrevImage = () => {
    const currentIndex = productImages.indexOf(selectedImage);
    const prevIndex = (currentIndex - 1 + productImages.length) % productImages.length;
    setSelectedImage(productImages[prevIndex]);
  };

  const handleNextImage = () => {
    const currentIndex = productImages.indexOf(selectedImage);
    const nextIndex = (currentIndex + 1) % productImages.length;
    setSelectedImage(productImages[nextIndex]);
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  return (
    <div className="w-full flex gap-4">
      {/* Left Side: Thumbnail productImages */}
      <div className=" flex flex-col gap-2">
        {productImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Product Image ${index + 1}`}
            className={`cursor-pointer w-48 h-48 border shadow-lg rounded-md ${
              selectedImage === image ? 'border-lightDark border-2' : 'border-gray-300'
            }`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>

      {/* Right Side: Selected Image with Zoom */}
      <div className="w-full md:w-3/4 relative">
        <div
          className={`h-full w-full bg-no-repeat bg-center border shadow-lg rounded-md ${isZoomed ? 'bg-cover' : 'bg-cover'}`}
          style={{ backgroundImage: `url(${selectedImage})` }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        
        {/* Next/Prev Controls */}
        <div className="w-full flex justify-between  mt-2 absolute top-[50%]">
           <button onClick={handlePrevImage} className='h-12 w-12 flex justify-center items-center bg-gray-700 rounded-full'><FaArrowLeft className='text-white font-bold' /></button>
           <button onClick={handleNextImage} className='h-12 w-12 flex justify-center items-center bg-gray-700 rounded-full'><FaArrowRight className='text-white font-bold'/></button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailCard;
