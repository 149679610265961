


export const pageURL = {
    landing: '/',
    shop: '/shop',
    productId: '/shop/:id',
    support: '/support',
    paymentCancel: '/cancel',
    paymentSuccess: '/success',
}