import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Landing } from '../pages/landing';
import { pageURL } from '../components/navigation/pagesURL';
import { Shop, ProductDetails } from '../pages/shop';
import {CancelPayment, PaymentSuccess} from '../pages/payments';

const PageRoutes = () => {
  return (
    <Router>
        <Routes>
            <Route path={pageURL.landing} element={<Landing/>} />
            <Route path={pageURL.shop} element={<Shop/>} />
            <Route path={pageURL.productId} element={<ProductDetails/>} />
            <Route path={pageURL.paymentSuccess} element={<PaymentSuccess/>} />
            <Route path={pageURL.paymentCancel} element={<CancelPayment/>} />
           {/* <Route path='*' element={<NotFound />} /> */}
        </Routes>
    </Router>
  )
}

export default PageRoutes