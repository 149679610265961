
import { useNavigate } from "react-router-dom";
import { ChevronRight, Ban } from 'lucide-react';

const CancelPayment = () => {

    const navigateTo = useNavigate();
    function handleRedirect(){
        navigateTo('/shop')
      }

  return (
    <main className='md:h-screen flex md:flex-row flex-col  justify-center items-center font-primary'>
        <div className='md:w-6/12'>
            <div className='flex flex-col justify-center items-center'>
                <div className='p-1 border rounded-full'>
                <h3 className='p-1 shadow-lg bg-light rounded-full'>
                    <p className='h-24 w-24 border flex items-center justify-center p-3 rounded-full bg-dark/60 '><Ban color='red' /></p>
                </h3>
                </div>
            </div>
            <div className='text-center my-6'>
                <h3>
                Sorry payment was not successfull!
                </h3>
            </div>
            <button 
            onClick={handleRedirect}
            className='m-auto flex items-center justify-center gap-2 py-2 px-3 
            rounded-md hover:text-light duration-300 ease-in bg-white'>
                continue to shop
                <span><ChevronRight /></span>
            </button>
        </div>
    </main>
  );
};

export default CancelPayment;
