
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaCartPlus, FaCaretRight } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { products } from '../../utils/constants/products';
import { IProductListProps } from '../../types/IProductListProps'
import { IProduct } from '../../types/Iproduct';
import { handleCheckout, generateWhatsAppLink } from '../../services/checkout.service';


const ProductList: React.FC<IProductListProps> = ({ addToCart }) => {

    // const generateWhatsAppLink = (product:any) => {
    //     const message = `Hello, I am interested in the product from your online shop: \n
    //     Name: \t ${product.name} \n
    //     Price: \t ${product.price}\n
    //     Image: \t ${product.image}\n
    //     Could you provide more details?`;
    //     return `https://wa.me/+447300999608?text=${encodeURIComponent(message)}`;
    // };

    const navigateTo = useNavigate();

    const handleViewProductDetails = (productId:string, product:IProduct)=>{
        navigateTo(productId, {
            state : product
        })
    }

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    return (

        <>
            
            <div className='p-10 my-10'>
                <motion.h1 
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        className='font-xl font-xbold text-lg text-lightDark md:text-[130px] 
                        leading-[3rem] md:leading-[7rem] font-primary'>
                        Our <br /> Shop
                </motion.h1>
            </div>
        
            <div className='bg-gray-100  flex items-center justify-center min-h-screen font-primary my-10 mx-5 md:mx-0'>
                <div className='grid md:w-10/12 md:grid-cols-3 gap-5'>
                    {products.map((product) => (
                        // <div className="max-w-xs mx-auto bg-white shadow-lg rounded-lg overflow-hidden" key={product.id}>
                        //     <img className="w-full h-48 object-cover object-center" src={product.image} alt="Product" />
                        //     <div className="p-4">
                        //         <h2 className="text-xl font-semibold text-gray-800">{product.name}</h2>
                        //         <p className="text-lg text-gray-700 mt-2">${product.price}</p>
                        //         <div className="mt-4 flex justify-between items-center">
                        //             <a 
                        //                 href={generateWhatsAppLink(product)} 
                        //                 target="_blank" 
                        //                 rel="noopener noreferrer"
                        //                 className="text-green-500 hover:text-green-700"
                        //             >
                        //                 <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                        //                     <path d="M12 0C5.371 0 0 5.371 0 12c0 2.136.559 4.204 1.618 6.034l-1.075 3.942 4.075-1.075A11.907 11.907 0 0012 24c6.629 0 12-5.371 12-12S18.629 0 12 0zm0 22c-2.116 0-4.159-.556-5.975-1.615L4.278 21.3l1.088-4.007A10.973 10.973 0 011 12C1 6.486 6.486 1 12 1s11 5.486 11 11-5.486 11-11 11z"/>
                        //                     <path d="M17.596 15.947l-.711-2.073c-.125-.366-.464-.614-.85-.614-.289 0-.576.122-.767.331l-1.211 1.255c-.716-.176-1.366-.537-1.908-1.086-.548-.547-.912-1.199-1.087-1.911l1.254-1.211c.206-.192.331-.48.331-.767 0-.386-.249-.725-.614-.85l-2.073-.711c-.298-.102-.618-.054-.872.13-.255.185-.403.482-.403.798 0 .05.006.1.014.148.283 1.865 1.23 3.599 2.645 4.977 1.417 1.416 3.151 2.364 5.017 2.645.047.008.098.014.148.014.316 0 .613-.148.798-.403.183-.254.23-.574.129-.871z"/>
                        //                 </svg>
                        //             </a>
                        //             <button
                        //                 onClick={() => addToCart(product)}
                        //                 className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                        //             >
                        //                 Add to Cart
                        //             </button>
                        //             <button
                        //                 onClick={() => handleViewProductDetails(product.id, product)}
                        //                 className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                        //             >
                        //                 Buy Product
                        //             </button>
                        //         </div>
                        //     </div>
                        // </div>
                        <div className="flex gap-3 max-w-x mx-auto bg-white shadow-lg rounded-lg overflow-hidden" key={product.id}>
                            <div className='w-1/2'>
                                <img className="w-full h-full object-cover object-center hover:scale-105 shadow-lg duration-300" src={product.image} alt="Product" />
                            </div>
                            <div className='w-1/2 px-2 relative'>
                                <span className='h-9 w-9 flex justify-center items-center bg-light rounded-full my-2 text-[12px] font-bold'>£{product?.price}</span>
                                <h2 className="text-sm font-bold text-lightDark mt-3">{product.name}</h2>
                                <p className='my-2 text-xsm'>{product?.description.length > 50 ? product.description.substring(0, 200) + '....' : product.description}</p>
                                <div className='py-3'>
                                    <button 
                                        onClick={() => handleViewProductDetails(product.id, product)}
                                    className='w-full py-2 flex justify-center items-center text-xsm gap-3 bg-light text-lightDark font-normal mt-1 rounded-full'>
                                        View Details
                                        <FaCaretRight className='text-lightDark font-bold h-4 w-4'/>
                                    </button>
                                    <button 
                                        onClick={()=>handleCheckout(product)}
                                    className='w-full py-2 flex justify-center items-center text-xsm gap-3 bg-lightDark text-white font-normal mt-1 rounded-full'>
                                        <FaCartPlus className='text-white font-bold h-4 w-4'/>
                                        Buy Now
                                    </button>
                                    <button 
                                        onClick={()=>generateWhatsAppLink(product)}
                                    className='w-full py-2 flex justify-center items-center text-xsm gap-3 bg-teal-500 text-white font-normal mt-1 rounded-full'>
                                        <FaWhatsapp className='text-white font-bold h-4 w-4'/>
                                        WhatsApp
                                    </button>
                                
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ProductList;
