
// import "@tremor/react/dist/esm/tremor.css";
import { Toaster } from 'sonner'
import { PageRoutes } from './routes';
import { CartContextProvider } from './contexts/cartContext';

function App() {
  return (
    <>
      <Toaster richColors position="top-right"/>
      <CartContextProvider>
        <PageRoutes />
      </CartContextProvider>
    </>
  );
}

export default App;
