import React, { useState } from 'react';
import { toast } from 'sonner';
import { ProductList } from '../../components/products';
import { Cart } from '../../components/cart';
import { IProduct } from '../../types/Iproduct';
import { ICartProduct } from '../../types/ICartProduct';
import { setCartItemsToLocalStorage }  from '../../services/cart.service'
import { DesktopNavigation, MobileNavigation, MobileNavigationHeader } from '../../components/header';
import { Footer } from '../../components/footer';

const Shop: React.FC = () => {
    const [cartItems, setCartItems] = useState<ICartProduct[]>([]);

    const [showNavItems, setShowNavItems] = useState(false);

    const handleToggleShowNavitems = ()=>{
      setShowNavItems(!showNavItems)
  }

    const addToCart = (product: IProduct) => {
        const existingProduct = cartItems.find(item => item.id === product.id);
        if (existingProduct) {
            setCartItems(cartItems.map(item => 
                item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
            ));
            setCartItemsToLocalStorage(cartItems.map(item => 
                item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
            ))
        } else {
            setCartItems([...cartItems, { ...product, quantity: 1 }]);
            setCartItemsToLocalStorage([...cartItems, { ...product, quantity: 1 }])
        }
        toast.success(`${product.name} added to cart`);
    };

    const removeFromCart = (product: IProduct) => {
        const existingProduct = cartItems.find(item => item.id === product.id);
        if (existingProduct) {
            if (existingProduct.quantity === 1) {
                setCartItems(cartItems.filter(item => item.id !== product.id));
                setCartItemsToLocalStorage(cartItems.filter(item => item.id !== product.id))
            } else {
                setCartItems(cartItems.map(item => 
                    item.id === product.id ? { ...item, quantity: item.quantity - 1 } : item
                ));
                setCartItemsToLocalStorage(cartItems.map(item => 
                    item.id === product.id ? { ...item, quantity: item.quantity - 1 } : item
                ))
            }
        }
        toast.success(`${product.name} removed from cart`);
    };

    return (
        <div>
            <DesktopNavigation/>
            <MobileNavigationHeader 
                    handleToggleShowNavitems={handleToggleShowNavitems}
                />
            <ProductList addToCart={addToCart} />
            <Cart cartItems={cartItems} removeFromCart={removeFromCart} />
            <Footer />
            {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
        </div>
    );
};

export default Shop;
