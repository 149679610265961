import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import certified from '../../../assets/jpg/green-certified.jpg';

const WorshipSession = ({ title, content, isImageSingle, singleImage }) => {

    const controls = useAnimation();
  const { ref, inView } = useInView();

  React.useEffect(() => {
      if (inView) {
        controls.start('visible');
      } else {
        controls.start('hidden');
      }
    }, [controls, inView]);

    const variants = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 50 },
      }
  return (
    <div className='md:h-screen flex flex-col justify-center mb-5 md:mb-0 font-primary'>
        <div className='md:w-8/12 m-auto flex flex-col-reverse md:flex-row items-center gap-10 px-2 md:px-0'>
            <div className="h-80 md:w-1/2 grid grid-rows-3 grid-flow-col gap-4">
                {
                    isImageSingle ? (<div className="h-full row-span-3">
                            <img src={singleImage} alt="" className='h-full object-cover' /></div>) : (
                        <>
                            <div className="h-full row-span-3">
                                <img src={certified} alt="" className='h-full object-cover' />
                            </div>
                            <div className="h-full col-span-2">
                                <img src={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641599/20240730_185412_xcvapd.jpg'} alt="" className='h-full object-cover w-full' />
                            </div>
                            <div className="h-full row-span-2 col-span-2">
                                <img src={'https://res.cloudinary.com/dtmsptewu/image/upload/v1722641590/20240730_181205_ygulpp.jpg'} alt=""  className='h-full object-cover' />
                            </div>
                        </>
                    )
                }
            </div>
            <div className='md:w-1/2 text-center md:text-start'>
                <motion.h2 
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                    transition={{ duration: 1.2, delay: 0.2 }}
                className='text-lg text-darkBlue md:text-[3.5rem] font-xbold leading-[2.6rem] md:leading-[4rem]'>
                    { title }
                    </motion.h2>
                <motion.p 
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                    transition={{ duration: 1.2, delay: 0.4 }}
                className='py-3 font-primary text-sm'>
                { content }
                </motion.p>
            </div>
        </div>
    </div>
  )
}

export default WorshipSession