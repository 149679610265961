import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from '../../contexts/cartContext';
import logo from '../../assets/jpg/zobs-wearables-logo.jpg';
import { getCartItems } from '../../services/cart.service';

const DesktopNavigation = () => {
    const navigateTo = useNavigate();
    const cartItems = getCartItems();
    const { showCart, handleShowCart } = useCartContext();
    console.log(cartItems, 'cartItems from store again');

    const handleRedirect = (item)=>{
        if(item.name==='Cart'){
            handleShowCart();
        }else{
            navigateTo(item.url)
        }
    }


    const navItems = [
        {
            name:'Home',
            url:'/',
            // icon:<TbListDetails />
        },
        {
            name:'Shop',
            url:'/shop',
            // icon:<TbListDetails />
        },
        // {
        //     name:'Cart',
        //     url:'/cart',
        // },
    ]

    
  return (
    <div className='hidden w-full m-auto  md:flex items-center justify-between bg-[#e5e4db] font-primary py-3 px-5'>
        <div className='flex items-center gap-2'>
            <img src={logo} 
                onClick={()=>navigateTo('/')}
                className='w-12 h-12 border-2 border-customPrimary rounded-full cursor-pointer p-1' alt="seventh day adventist chirch liberty chapel" 
            />
            <h4>ZobsWearables</h4>
        </div>
        <ul className='flex items-center'>
            {
                navItems && navItems.map((item, index)=>(
                    <li 
                        onClick={()=>handleRedirect(item)}
                        key={index}
                        className='flex items-center gap-1 text-blue text-xsm cursor-pointer hover:text-yellow 
                        ease-in duration-500 font-medium'
                    >
                        <span className='ml-5'>{item?.icon}</span>
                        {item.name === 'Cart' ? `${cartItems?.length || 0 }` : ''} 
                        {item.name}
                    </li>
                ))
            }
        </ul>
    </div>
  )
}

export default DesktopNavigation