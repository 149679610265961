
export const setCartItemsToLocalStorage = (item)=>{
    if(item){
        const cartItem = localStorage.setItem('cartItems', JSON.stringify(item)) || [];
        return cartItem
    }else{
        return null
    }
}

export const getCartItems = ()=>{
    const cartItem = JSON.parse(localStorage.getItem('cartItems'));
    return cartItem
}